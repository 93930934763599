import React, { Component } from "react";

export default class Logo extends Component {
  render() {
    return (
      <svg
        className="Logo"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width="827"
        height="214"
        viewBox="0 0 827 214"
      >
        <title>Frihet</title>

        <rect width="827" height="214" fill="#DC1E0F" />
        <path
          d="M794 55.0429L791.819 57.2286H748.19L746.008 59.4143V181.814L743.827 184H717.65L715.468 181.814V59.4143L713.287 57.2286H669.658L667.477 55.0429V33.1857L669.658 31H791.819L794 33.1857V55.0429Z"
          fill="white"
        />
        <path
          d="M650.051 181.814L647.87 184H545.342L543.161 181.814V33.1857L545.342 31H647.87L650.051 33.1857V55.0429L647.87 57.2286H575.882L573.701 59.4143V90.0143L575.882 92.2H643.507L645.688 94.3857V116.243L643.507 118.429H575.882L573.701 120.614V155.586L575.882 157.771H647.87L650.051 159.957V181.814Z"
          fill="white"
        />
        <path
          d="M508.156 181.814L505.974 184H479.797L477.615 181.814V120.614L475.434 118.429H416.535L414.354 120.614V181.814L412.172 184H385.995L383.814 181.814V33.1857L385.995 31H412.172L414.354 33.1857V90.0143L416.535 92.2H475.434L477.615 90.0143V33.1857L479.797 31H505.974L508.156 33.1857V181.814Z"
          fill="white"
        />
        <path
          d="M348.954 181.814L346.772 184H320.595L318.413 181.814V33.1857L320.595 31H346.772L348.954 33.1857V181.814Z"
          fill="white"
        />
        <path
          d="M292.194 76.9C292.194 100.943 276.924 116.243 259.472 120.614V122.8L287.831 159.957V181.814L285.649 184H272.561L268.198 181.814L224.569 124.986L220.206 122.8H198.392L196.211 124.986V181.814L194.029 184H167.852L165.67 181.814V33.1857L167.852 31H239.839C270.379 31 292.194 46.3 292.194 76.9ZM261.654 76.9C261.654 61.6 252.928 57.2286 239.839 57.2286H198.392L196.211 59.4143V94.3857L198.392 96.5714H239.839C252.928 96.5714 261.654 92.2 261.654 76.9Z"
          fill="white"
        />
        <path
          d="M139.527 55.0429L137.346 57.2286H69.7215L67.5401 59.4143V92.2L69.7215 94.3857H132.983L135.165 96.5714V118.429L132.983 120.614H69.7215L67.5401 122.8V181.814L65.3586 184H39.1814L37 181.814V33.1857L39.1814 31H137.346L139.527 33.1857V55.0429Z"
          fill="white"
        />
      </svg>
    );
  }
}
